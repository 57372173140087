<template>
<div :style="getAppStyle()">
    

	<div class='dontWrap invers bold' :style="getAppStyle()+'padding-left: 5pt; padding-top: 3pt; height: 24pt !important; background-color: '+getBGColor('#ccc','#666')+' !important; font-size: 15px; height: 22pt; width: 100%; margin-bottom: 15px;'"> 
	    <div :style="getAppStyle()+'float: left; display: flex; background-color: transparent; vertical-align: bottom;'"  class='invers bold'>
			<mdicon :width="16" name="clipboard-outline" />  
				<b>CLIPBOARD</b>  
				<span style="display: inline-block; padding-top: 2pt; padding-left: 8pt; font-size:9pt;">
					({{myData.length}} spots)
				</span>
			
		</div>	
		<WindowCloseBN style='float: right; padding-top: 1pt;' @click="$emit('closeDay', dayId)"/>
	</div>
 	<div :style="getAppStyle()+'width: 100%; height: calc(83.5vH); overflow-y: scroll; position: relative; font-size: 12px;'"> 
	 	<div>
		 	<SimpleSpotList 
		 		@moveSpots=moveSpots 
		 		@deleteSpot=deleteSpot 
		 		@contextmenu=contextmenu
		 		:update=update 
		 		identifier="Clipboard" 
		 		:user=user 
		 		@userUpdated=userUpdated
				:selectedIN=selectedIN
		 		:spots="myData">
		 	</SimpleSpotList>
	 	</div>
	 </div>
	
	 <ContextMenu ref="menu" :offsetY="-100">
      <template v-if="contextData"  slot-scope="{ contextData }">
        <ContextMenuLabel>
      
         {{contextData.spot.placementName}}
        </ContextMenuLabel>
        <ContextMenuSep/>
        <ContextMenuItem v-if="contextData.spot.placementId" @clicked="closeCMN(); openBookMan(contextData.spot)">
         Open Traffic Manager
        </ContextMenuItem>
        <ContextMenuSep/>
        <ContextMenuItem v-if="contextData.spot.copyId" @clicked="closeCMN(); $refs.editor.open('Copy', contextData.spot.copyId)">
         Open {{tr("Copy")}} {{contextData.spot.copyName}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.spot.placementId" @clicked="closeCMN(); $refs.editor.open('Placement', contextData.spot.placementId)">
         Open {{tr("Placement")}} 
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.spot.placementId" @clicked="closeCMN(); showAllFilters( contextData.spot)">
         Show all {{tr('Inventory')}}
        </ContextMenuItem>
        <ContextMenuSep v-if="preemptionReasons.length"/>
        <ContextMenuLabel>
        	Preempt:
        </ContextMenuLabel>
        <ContextMenuItem v-for="(r,i) in preemptionReasons" :key='"pi"+i' @clicked="closeCMN(); preempt([contextData.spot], r)">
         <span style='font-size: 8pt;padding-left:10pt;'>{{r.name}}</span>
        </ContextMenuItem>
        
       </template>
    </ContextMenu>
    <GFWEOpenEditor ref='editor' @update='$emit("reload")'></GFWEOpenEditor>
    <InfoPopUp :time=infoTime :infoType=asText :info=showAsText></InfoPopUp>
</div>
</template> 
<script>
import {HTTP, fwAPI, invAPI, inventoryInlineAPI, showError, setReload, myLocale} from '@/variables.js';
import { tr } from '@/translate.js';
import { setGoBack } from '@/breadCrumb.js';
import { getAppStyle, getBGColor } from '@/AppStyle.js';
import {fmtTimePart, printTimeOpt, printTime2, getHour } from '@/basicTimeFN.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';
import InfoPopUp from '@/components/InfoPopUp';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuLabel from '@/components/ContextMenuLabel';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import InputTimezone from '@/components/inputElements/InputTimezone';
import SimpleSpotList from '@/components/SimpleSpotList';
import InputDatePick from '@/components/inputElements/InputDatePick'; 
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

export default {
  name: 'GFWRunDownViewCLB',
  components : {
   SimpleSpotList, ContextMenu, ContextMenuItem, ContextMenuSep, ContextMenuLabel, WindowCloseBN, InfoPopUp
  },
  props: {
    date: Date,
    selectedPrograms: Array,
    selectedClients: Array,
    selectedProducts: Array,
    selectedCopies: Array,
    selectedBreaks: Array,
	selectedRateTypes: Array,
	selectedPG: Array,
    selectedIN: String,
    preempted: Object,
    CBFilter: Object,
    filterFor: String,
    preemptionReasons: Array,
    user: Object,
    update: Number,
    dayId: String,
    data: Object
  },
  data () {
    return {
      tr,getAppStyle,getBGColor,
      myData: {},
      asText: "",
      showAsText: "",
      infoTime: 0, 
    }
  },
  methods: {
    myLocale() { return myLocale();},
    getRawKey(brk)
     {
    	return brk.gridId+":"+brk.mediaId+":"+brk.breakNo+":"+brk.breakTypeId+":"+brk.date;
     },
    getRef(idx1,idx2) { return "SSL_"+idx1+"."+idx2;},
    getHeaderStyle() {
    	if ( this.selectedState )
    	{
    		return "background-color: "+this.getState().colour+";";
    	}
    	return "";
    },
    userUpdated( user) { this.user=user; this.$emit("userUpdated", user );  },
	contextmenu(event, spot){
        this.$refs.menu.open(event, spot )
	},
	closeCMN() { try{ this.$refs.menu.close() } catch(e) { /* */ }},
	toggleMarks(ctx){
		if ( Array.isArray(this.$refs[ctx.name]))
		{
			this.$refs[ctx.name][0].toggleMarks();
		}
		else
		{
			this.$refs[ctx.name].toggleMarks();
		}
	},
	preempt( spots, reason ) { this.$emit("preempt", spots, reason); },
	printTime2( val) { return printTime2(val);},
	setPrice( spots, price ) { this.$emit("setPrice", spots, price); },
	moveSpots( dndType, spots, target, source, fromId, toId){ console.log("moveSpots(RD) "+dndType); this.$emit("moveSpots", dndType, spots, target, source, fromId, toId)},
	deleteSpot( spots, fromId ) { this.$emit("deleteSpot", spots, fromId, true) },
	getSlotClass(grid, selectedPrograms) {
		if ( !selectedPrograms || !selectedPrograms.length || selectedPrograms.includes(grid.detail.name))
		{
			return "transition";
		}
		return "transition invisible";
	},
	getState()
	{
		return this.myData.statusList.find( p => p.shortname === this.selectedState);
	},
	showAllFilters( spot)
	{
		let that = this;
		HTTP.post( inventoryInlineAPI+"/getAllFilterForBookings/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, spot)
		 .then( response => 
   		{
   			that.showAsText = "<ul style='padding-left: 3ex; list-style-type: circle;'>";
   			that.showAsText += "<li>"+tr('Placement')+": " + response.data.placementFilter.title+"</li>";
   			that.showAsText += "<li>"+tr('Client')+": " + response.data.clientFilter.title+ "</li>";
   			that.showAsText += "<li>"+tr('Product')+": " + response.data.productFilter.title+ "</li>";
   			that.showAsText += "<li>"+tr('Copy')+": " + response.data.copyFilter.title+ "</li>";
   			that.showAsText += "</ul>";
            //this.showAsText = JSON.stringify(response.data);
            that.infoTime = new Date().getTime();
            that.asText = "All "+tr("Inventory");
   		}).catch(e => {
            
            showError( that.$toast, "show filters", e)
    	});
	},
	getBreakCode(adSpace, brk)
      {
        let code = "";
        if ( adSpace.section == 0 )
        {
        	code = "";
        }
        else if ( adSpace.section == 2 )
        {
        	code =  "";
        }
        else
        {
        	code =  "C-"+adSpace.no;
        }
        code += " "+brk.shortname;
       
      	return code;
      },
	doFilterSpots( data)
    {
        if ( this.filterFor.includes("All") || this.filterFor.includes("Clipboard") )
     	{
	    	let tmp = data;
			if ( tmp )
			{
				if ( this.selectedPrograms && this.selectedPrograms.length > 0 )
		     	{
		     		tmp =  tmp.filter(spot => this.selectedPrograms.includes(spot.gridName) );
		     	}
		     	if ( this.selectedBreaks && this.selectedBreaks.length > 0 )
		     	{
		     		tmp =  tmp.filter(spot => this.selectedBreaks.includes(spot.breakTypeShort) );
		     	}
		     	
		     	if ( this.selectedClients && this.selectedClients.length > 0 )
		     	{
		     		tmp =  tmp.filter(spot => this.selectedClients.includes(spot.clientName) );
		     	}
				if ( this.selectedRateTypes && this.selectedRateTypes.length > 0 )
		     	{
		     		tmp =  tmp.filter(spot => this.selectedRateTypes.includes(spot.rateType) );
		     	}
		     	if ( this.selectedProducts && this.selectedProducts.length > 0 )
		     	{
		     		tmp =  tmp.filter(spot => this.selectedProducts.includes(spot.productName) );
		     	}
		     	if ( this.selectedCopies && this.selectedCopies.length > 0 )
		     	{
		     		tmp =  tmp.filter(spot => this.selectedCopies.includes(spot.copyName) );
		     	}
				if ( this.selectedPG && this.selectedPG.length > 0 )
		     	{
		     		tmp =  tmp.filter(spot => this.selectedPG.includes(spot.pricegroup) );
		     	}
		    }
			return tmp;
		}
		return data;
    },
     openBookMan( spot ) {
        setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
    	this.$router.replace('bookMan?toOpen=Placement&list=true&id='+ spot.placementId);
    },  
	set( data)
	{
		this.myData = this.doFilterSpots( data)
	}
  },
 
  updated() {
    this.$nextTick(function () {
  	this.$refs.menu.open(event, {spot: {}, name: "" });
	this.closeCMN();
	});
  },
  created() { 
    this.set( this.data);
	this.$nextTick(function () {
  	this.$refs.menu.open(event, {spot: {}, name: "" });
	this.closeCMN();
	});
  },
  watch: {
    stateName: function() {
	    this.set( this.data);
	},
	data: function() {
	    this.set( this.data);
	},
	date: function() {
	    this.set( this.data);
	},
	update: function() {
	    this.set( this.data);
	},
	selectedPrograms: function() {
	    this.set( this.data);
	},
	filterFor: function() {
	    this.set( this.data);
	},
	selectedCopies: function() {
	    this.set( this.data);
	},
	selectedBreaks: function() {
	    this.set( this.data);
	},
	selectedClients: function() {
	    this.set( this.data);
	},
	selectedProducts: function() {
	    this.set( this.data);
	},
	selectedPG: function() {
		this.set( this.data);
	},
	selectedRateTypes: function() {
		this.set( this.data);
	}
  },
 }
</script>

<style scoped>

.invisible {
	display: none;
}
.transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.transitionFast {
 	transition: all .2s;
   -webkit-transition: all .2s;
}
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.SVcontrolLabel {
	font-weight: bold;
	font-size: 10pt;
	padding-top: 9pt;
}
.RDHeader {
    display: inline;
	width: 99% !important;
	font-size: 12px;
	height: 100% !important;
	font-weight: bold;
}
.RDHeaderText {
	padding-top: 3pt;
	padding-left: 2pt;
}
.RDProgramTitle {
    display: inline-flex;
    padding-left: 5pt;
	font-size: 14px;
	font-weight: bold;
	background-color: #f8f8f8;
}
.RDProgramTime {
    display: inline-flex;
	font-size: 14px;
	width: 120px;
	color: #444;
	font-weight: bold;
	background-color: #f8f8f8;
}
.RDProgramTime2 {
    display: inline-flex;
	font-size: 14px;
	padding-left: 10pt;
	color: #888;
	font-weight: bold;
	background-color: #f8f8f8;
}
.RDProgram {
	font-size: 14px;
	font-weight: bold;
	background-color: #f8f8f8;
}
.RDBreakTitle {
	
	font-weight: bold;
	
}
.RDBreakTime {
    display: inline-flex;
	width: 120px;
	color: #444;
	font-weight: bold;
	background-color: #f8f8f8;
}
.RDBreakTime2 {
    display: inline-flex;
	padding-left: 10pt;
	color: #888;
	font-weight: bold;
	background-color: #f8f8f8;
}
.RDBreak {
	font-size: 11px;
	font-weight: bold;
	background-color: #f8f8f8;
	border-top: 2px outset #ddd;
	text-align: left;
	padding-left: 6pt;
}
.RDOpenProgram {
    margin-top: 2pt;
    margin-bottom: 8pt;
    padding: 2px;
	border-top: 2px outset #ddd;
	border-left: 1px outset #888;
	border-bottom: 1px outset #ccc;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}
.invers {
	padding-left: 8pt; 
	padding-right: 4pt;
	width: 100%; 
	height: 18pt;
	padding-top: 1pt;
	
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100% !important;
  display: flex;
}
</style> 