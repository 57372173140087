<template>
<div @contextmenu.prevent="contextmenu($event)" :style="getAppStyle()+'height: calc(100vH - 150px) !impoertant;'">
    
    <div style='display: flex; width: 99.8% !important;' class='RDHeader' :style='getHeaderStyle()'>
        <div style='display: flex; width: 98% !important;' :style='getHeaderStyle()'>
           <div style='width:18%'>
	        <InputDatePick :auto='false' style='font-size: 9pt; width:100px; height:28px;' @change="dateChanged" v-model="myDate"/>
	       </div>
	        <vSelect placeholder='choose channel' :options="stations" style='width:100%' v-model="selectedStation" @input='stationChanged'> 
		    </vSelect>
	    	

		 </div>
		<div class='RDHeaderText' style='text-align:right; width: 12%; padding-right: 5pt;' >
		<svg @click="$emit('closeDay', dayId)" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
				  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
				  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
		</div>
    </div>
    
	<div  :style="getAppStyle()+'width: 100%; position: relative; font-size: 12px; height: calc(82vH) !important; overflow-y; scroll;'">
		<div class='transition noWrap' :style="getAppStyle()+'width: 99.8% !important;height: 12pt; margin: 0pt; padding: 0pt; font-size:8pt;'"> <!--+getHeaderStyle()-->
		<div style="display: flex; float: left;">
		
			Revenue {{formatNumber(data.dayStats.revenueHC)+' '+sessionStorage.hCurrency}}, Spots {{data.dayStats.spots}}, Booked seconds 
			{{ printTimeMMSS( data.dayStats.seconds) }} of {{ printTimeMMSS( data.dayStats.capacity) }} 
		</div>
		
		
	</div>
		<div :style="'background-color: '+getBGColor('#eef','#226')+'; margin-top: 2pt; border: 1px inset grey; padding: 3pt; font-size: 10pt;'">
		<b>QUICK ANALYSER </b>
		</div>
		
		
		<template v-for="(l,idx) in filters" >
			<button :style='getAppStyle()' v-if="selectedFilter.includes(l.name)" :key="'fi'+idx" type='button' class="button myButton selected" @click="addFilter(l.name)">{{l.name}}</button>
			<button :style='getAppStyle()' v-else :key="'fi'+idx" type='button' class='button myButton' @click="addFilter(l.name)">{{l.name}}</button>
		</template>
		<br/>
		<button :style='getAppStyle()' type='button' :class="'sorter button myButton '+((sort=='name')?'selected':'')" @click="sort='name'; sortSummary()">Sort by name</button>
		<button :style='getAppStyle()' type='button' :class="'sorter button myButton '+((sort=='spots')?'selected':'')" @click="sort='spots'; sortSummary()">Sort by spots</button>
		<button :style='getAppStyle()' type='button' :class="'sorter button myButton '+((sort=='seconds')?'selected':'')" @click="sort='seconds'; sortSummary()">Sort by seconds</button>
		<button :style='getAppStyle()' type='button' :class="'sorter button myButton '+((sort=='value')?'selected':'')" @click="sort='value'; sortSummary()">Sort by revenue</button>
		<br/>
		
		<div style='height: calc(36vH); overflow-y: scroll;'>
		<table width="100%" class="xfixed_header">
		<thead :style="getAppStyle()+'position: sticky; top: 0px; width: 100%;'">
        <tr >
          <template v-if="!selectedFilter.length">
          <th id='h0' class='fxTableHeader noOverflow'></th>
          </template>
          <template v-for="(lbl, lidx) in selectedFilter">
			<th :id="'h'+lidx" class='fxTableHeader noOverflow' :key="'lbl'+lidx">
			   {{lbl}}
			</th>
		  </template>
          <th class='fxTableHeader noOverflow rightAlign' :title="'Number of different '+tr('Placement')+'s'">LI</th>
          <th class='fxTableHeader noOverflow rightAlign' title="Number of spots">Spots</th>
          <th class='fxTableHeader noOverflow rightAlign' title="Booked volume">Seconds</th>
          <th class='fxTableHeader noOverflow rightAlign' title="GRP in placement targetgroup">GRP</th>
          <th class='fxTableHeader noOverflow rightAlign' title="Ratecard price">Ratecard</th>
          <th class='fxTableHeader noOverflow rightAlign' title="Value in house-currency">Price</th>
        </tr>
      
        
		
		<tr v-if="total && total.ready" class="total">
		
		<th :style="getAppStyle()"  class="total" :colspan="selectedFilter.length">
		<div><div class='total'>{{ total.label}}</div></div></th>
		<th :style="getAppStyle()" class='numeric'><div class='numeric total'>{{ total.placementId.length}}</div></th>
		<th :style="getAppStyle()" class='numeric'><div class='numeric total'>{{ total.count}}</div></th>
		<th :style="getAppStyle()"  class='numeric'><div class='numeric total'>{{ total.vol}}</div></th>
		<th :style="getAppStyle()"  class='numeric'><div class='numeric total'>{{ formatNumber( total.grp)}}</div></th>
		<th :style="getAppStyle()"  class='numeric'><div class='numeric total'>{{ formatNumber( total.gross3)}}</div></th>
		<th :style="getAppStyle()"  class='numeric'><div class='numeric total'>{{ formatNumber( total.grossHC)}}</div></th>

		</tr>
		
		
		</thead>
        <tbody>
		<tr v-for="(info,idx) in summary" :key="'i'+idx" class="selectable" 
		    @click="info.placementId.length==1?showAllFilters(info):filterStructure=null"
		    @dragstart="startDragSpot($event, info.sample, 'copy')"
	    	draggable
		    @contextmenu.prevent="contextmenu( $event, info)">
		<template v-if="info && info.label">
		    <template v-if="!selectedFilter.length">
	          <td></td>
	         </template>
			<template v-for="(lbl, lidx) in info.label">
			<td @contextmenu.prevent="contextmenu( $event, info, lbl)" :style="'font-size:'+fontSize+'px; color: ' + (info.count?getFGColor('#000','#fff'):getFGColor('#888','#aaa'))" :title="lbl.label" :key="'lbl'+lidx">{{lbl.label}}</td>
			</template>
		
		    <td :style="'font-size:'+fontSize+'px; color: ' + (info.count?getFGColor('#000','#fff'):getFGColor('#888','#aaa'))" class='numeric'>{{ info.placementId.length}}</td>
			<td :style="'font-size:'+fontSize+'px; color: ' + (info.count?getFGColor('#000','#fff'):getFGColor('#888','#aaa'))" class='numeric'>{{ info.count}}</td>
			<td :style="'font-size:'+fontSize+'px; color: ' + (info.count?getFGColor('#000','#fff'):getFGColor('#888','#aaa'))" class='numeric'>{{ info.vol}}</td>
			<td :style="'font-size:'+fontSize+'px; color: ' + (info.count?getFGColor('#000','#fff'):getFGColor('#888','#aaa'))" class='numeric'>{{ formatNumber( info.grp)}}</td>
			<td :style="'font-size:'+fontSize+'px; color: ' + (info.count?getFGColor('#000','#fff'):getFGColor('#888','#aaa'))" class='numeric'>{{ formatNumber( info.gross3)}}</td>
			<td :style="'font-size:'+fontSize+'px; color: ' + (info.count?getFGColor('#000','#fff'):getFGColor('#888','#aaa'))" class='numeric'>{{ formatNumber( info.grossHC)}}</td>
		</template>
		</tr>
		</tbody>
		</table>
		
		</div>
		  
		 <div v-if="!showChart && filterStructure" class="transitionOptimizer settingsBlock" 
		     :style="'font-size:'+(fontSize+2)+'px;'">
		        <div class='smallTextFull' style='padding-left: 0pt; cursor: pointer' @click="$refs.editor.open('Placement', filterStructure.placement.id)"> Placement: {{filterStructure.placement.name}}</div> 
	            <br/>
				<span class='smallTextFull bold'>{{tr('Placement')}}:</span>
	            <InventoryFilterEditor  
		                 	  	v-model="filterStructure.placementFilter"
		                 	  	:columnName="'placementFilter'"
		                 	  	:parentRecord="filterStructure"
		                 	  	:hideTemplates="true"
		                 	  	@open="openInvEditor"
		                 	  ></InventoryFilterEditor>
		       <span class='smallTextFull bold'>{{tr('Client')}} {{filterStructure.client.name}}:</span>          	  
		       <InventoryFilterEditor  
		                 	  	v-model="filterStructure.clientFilter"
		                 	  	:columnName="'clientFilter'"
		                 	  	:parentRecord="filterStructure"
		                 	  	:hideTemplates="true"
		                 	  	@open="openInvEditor"
		                 	  ></InventoryFilterEditor>
		       
		       	 <span  class='smallTextFull bold'>{{tr('Product')}} {{filterStructure.product.name}}:</span>          	  
		         <InventoryFilterEditor  
		                 	  	v-model="filterStructure.productFilter"
		                 	  	:columnName="'productFilter'"
		                 	  	:parentRecord="filterStructure"
		                 	  	:hideTemplates="true"
		                 	  	@open="openInvEditor"
		                 	  ></InventoryFilterEditor>
		       
		       <div v-if='hasFilter( filterStructure.copyFilter)'>
		        <span class='smallTextFull bold'>{{tr('Copy')}}:</span>         	  
		        <InventoryFilterEditor  
		                 	  	v-model="filterStructure.copyFilter"
		                 	  	:columnName="'copyFilter'"
		                 	  	:parentRecord="filterStructure"
		                 	  	:hideTemplates="true"
		                 	  	@open="openInvEditor"
		                 	  ></InventoryFilterEditor>
		        </div>
	            <div style='display: table; position: absolute; bottom: 0; width: 100%;'>
	            <button title='Save the filters, so that they are respected in the next optimization' :disabled='!filterStructure.updateFilters' :style="getAppStyle()+'float: right; width: 5em !important; height: 30pt !important;'" class='button myButton' @click='saveAllFilters(filterStructure)'>SAVE</button>
				</div>
	            
	        </div>
	     <div v-show='showChart && activeFilter && activeFilter.length' :style="getAppStyle()+'width: 100%; border-top: 1px solid grey; padding-top: 10pt; position: relative; font-size: 12px;'">
	 			<div  style='float: right;'>   
  		        	<WindowCloseBN @click="showChart=false" tooltip='close chart'/>	    
		  		</div>	
	 	        <div style="float: left; width: 100%; color: #000;" id='pieChart' />
		       
		 </div>	
		
	 </div>
	 <ContextMenu ref="menu" :offsetY="-100">
      <template v-if="contextData"  slot-scope="{ contextData }">
      
        <ContextMenuItem v-if="showChart" @clicked="closeCMN(); showChart=false;">
         Hide chart 
        </ContextMenuItem>
         <ContextMenuItem v-if="!showChart" @clicked="closeCMN();showChart=true">
         Show chart 
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData && contextData.line && contextData.line && contextData.line.placementId.length==1"
          @clicked="closeCMN();$refs.editor.open('Placement', contextData.line.placementId[0])">
         Open {{tr('Placement').toLowerCase()}}
        </ContextMenuItem>
        <ContextMenuItem @clicked="closeCMN(); showAllPlacements();">
         Show all {{tr('Placement').toLowerCase()}}s 
        </ContextMenuItem>
                
        <ContextMenuSep/>
       
        <ContextMenuItem v-if="contextData && contextData.label && contextData.label.filter==='clientId'" 
                         @clicked="closeCMN(); $emit('addFilter', contextData.label.filter, contextData.label.label)">
         Filter {{contextData.label.label}}
        </ContextMenuItem>
        
        <ContextMenuItem v-if="contextData && contextData.label && contextData.label.filter==='productId'" 
                         @clicked="closeCMN(); $emit('addFilter', contextData.label.filter, contextData.label.label)">
         Filter {{contextData.label.label}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData && contextData.label && contextData.label.filter==='copyId'" 
                         @clicked="closeCMN(); $emit('addFilter', contextData.label.filter, contextData.label.label)">
         Filter {{contextData.label.label}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData && contextData.label && contextData.label.filter==='breakTypeId'" 
                         @clicked="closeCMN(); $emit('addFilter', contextData.label.filter, contextData.label.label)">
         Filter {{contextData.label.label}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData && contextData.label && contextData.label.filter==='gridId'" 
                         @clicked="closeCMN(); $emit('addFilter', contextData.label.filter, contextData.label.label)">
         Filter {{contextData.label.label}}
        </ContextMenuItem>
        
        <ContextMenuItem v-if="contextData && contextData.line && contextData.line.placementId && contextData.line.placementId.length==1" @clicked="closeCMN(); openBookMan(contextData.line.placementId[0])">
         Open Traffic Manager 
        </ContextMenuItem>
        
        
        
       </template>
    </ContextMenu>
	<GFWEOpenEditor ref='editor' @update='doReload'></GFWEOpenEditor>
	<GFWEditorInventory :ref='getInvEditorId()' @inventory='setInventory' ></GFWEditorInventory>
</div>
</template> 
<script>
import {HTTP, fwAPI, invAPI, inventoryInlineAPI, bngAPI, setReload, myLocale, showError, formatNumber} from '@/variables.js';
import {fmtTimePart, printTimeOpt, printTime2, getHour, printTimeHHMM, printTimeMMSS } from '@/basicTimeFN.js';

import { getAppStyle, setDarkMode, initAppMode, hexToRgb, isDark, getFGColor, getBGColor } from '@/AppStyle.js';
import { tr } from '@/translate.js';
import { setGoBack } from '@/breadCrumb.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';

import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import InputTimezone from '@/components/inputElements/InputTimezone';
import SimpleSpotList from '@/components/SimpleSpotList';
import InputDatePick from '@/components/inputElements/InputDatePick2'; 
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import GFWEditorInventory from '@/components/GFWEditorInventoryInline';
import InventoryFilterEditor from '@/components/inputElements/InventoryFilterEditor';
import vSelect from 'vue-select'
import GProgress from '@/components/misc/GProgressMedium';
import 'vue-select/dist/vue-select.css';
import ApexCharts from 'apexcharts'
var momentTZ = require('moment-timezone')
export default {
  name: 'GFWRunDownViewChart',
  components : {
   vSelect, WindowCloseBN, InputDatePick, ContextMenu, ContextMenuItem, ContextMenuSep, InventoryFilterEditor, GFWEditorInventory
  },
  props: {
    station: Object,
    stations: Array,
    date: Date,
    fontSize: Number,
    showEmptyBreaks: Boolean,
    showDangling: Boolean,
    selectedPrograms: Array,
    selectedClients: Array,
    selectedProducts: Array,
	selectedRateTypes: Array,
    selectedCopies: Array,
	selectedPG: Array,
    stateName: String,
    timezone: Object,
    update: Number,
    dayId: String,
    data: Object
  },
  data () {
    return {
      filters: 
    	  [{ name: tr("Client"), id: "clientId", label: "clientName", type: "String", w: 100 },
    	   { name: tr("Agency"), id: "agencyId", label: "agencyName", type: "String", w: 100 },
    	   { name: tr("Campaign"), id: "campaignId", label: "campaignName", type: "String", w: 100 },
    	   { name: tr("Placement"), id: "placementId", label: "placementName", type: "String", w: 100 },
    	   { name: tr("Product"), id: "productId", label: "productName", type: "String", w: 100 },
    	   { name: tr("Family"), id: "productFamilyId", label: "productFamily", type: "String", w: 100 },
    	   { name: tr("Copy"), id: "copyId", label: "copyName", type: "String", w: 100 },
    	   { name: tr("Dur"), id: "duration", label: "duration", type: "numeric", w: 20 },
    	   { name: tr("Pos"), id: "position", label: "position", type: "numeric", w: 20 },
    	   { name: tr("BusType"), id: "businessTypeId", label: "businessType", type: "String", w: 50 },
    	   { name: tr("Type"), id: "placementTypeId", label: "placementType", type: "String", w: 60 },
    	   { name: tr("PRQ"), id: "positionRequest", label: "positionRequest", type: "String", w: 30 },
    	   { name: tr("Priority"), id: "priority", label: "priority", type: "numeric", w: 20 },
    	   { name: tr("Discount"), id: "discount", label: "discount", type: "numeric", w: 30 },
    	   { name: tr("Currency"), id: "currencyId", label: "currency", type: "String", w: 30 },
    	   { name: tr("PlacementEnd"), id: "placementTo", label: "placementTo", type: "String", w: 50 },
    	   { name: tr("Grid"), id: "gridId", label: "gridName", type: "String", w: 100 },
    	   { name: tr("XChange"), id: "exchangeable", label: "exchangeable", type: "boolean", w: 8 },
    	   { name: tr("PG"), id: "pricegroup", label: "pricegroup", type: "numeric", w: 25 },
		   { name: tr("RatecardType"), id: "rateType", label: "rateType", type: "numeric", w: 25 },
    	   { name: tr("Status"), id: "statusId", label: "statusName", type: "String", w: 50 },
    	   { name: tr("Time"), id: "gridTime", label: "gridTime", type: "Time", w: 50 },
    	   { name: tr("BreakNo"), id: "breakNo", label: "breakNo", type: "numeric", w: 20 },
    	   { name: tr("BreakType"), id: "breakTypeId", label: "breakTypeShort", type: "numeric", w: 20 }
    	   ],	
      myId: Math.round(Math.random()*100000000),
      selectedFilter: [tr("Client")],
      myData: {},
      myDate: this.date,
      summary: {},
      sort: "name",
      allPossibleSpots: [],
      showChart: false,
      total: {},
      filterStructure: null,
      diff: 0,
      chart: null,
      activeFilter: [],
      chartOptions: null,
      selectedState: {},
      selectedStation: {},
      statusList: [],
      controlsRight: false,
      printTimeMMSS,
      getAppStyle,getFGColor,getBGColor,
      printTime2, 
      sessionStorage,
      tr, 
      formatNumber
    }
  },
  methods: {
    myLocale() { return myLocale();},
    getRef(idx1,idx2) { return "SSL_"+idx1+"."+idx2;},
    getHeaderStyle() {
    	if ( this.selectedState )
    	{
    		return "background-color: "+this.getState().colour+";";
    	}
    	return "";
    },
    startDragSpot (evt, spot, what) {
  		//console.log( "WHAT(startDragSpot)---->" + what);
  		evt.dataTransfer.setData('dndType', what);
        evt.dataTransfer.dropEffect = what
        evt.dataTransfer.effectAllowed = what
        
        var mySpots = new Array();
        evt.dataTransfer.setData('spotSource', 0)
        evt.dataTransfer.setData('dayId', this.dayId)
     	mySpots.push(spot);
        evt.dataTransfer.setData('spotIndex', 0)
        evt.dataTransfer.setData('spot', JSON.stringify(spot))
        return;
    },
    contextmenu(event, line, label){
    	event.stopPropagation();
	    event.preventDefault();
	    if ( line )
	    {
	    	if ( label )
	    	{
	    		this.$refs.menu.open(event, {line: line, label: label} )
	    	}
	    	else
	    	{
        		this.$refs.menu.open(event, {line: line} )
	    	}
	    }
	    else
	    {
	    	this.$refs.menu.open(event, {} )
	    }
	},
	dateChanged(date) {
		this.$emit("dateChanged", this.dayId, date)
	},
	stationChanged(selectedStation) {
		this.$emit("stationChanged", this.dayId, selectedStation.id)
	},
	openBookMan( placementId ) {
        setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
    	this.$router.replace('bookMan?toOpen=Placement&list=true&id='+ placementId);
    },  
	addFilter( filter)
	{
		if ( this.selectedFilter.includes( filter))
		{
			this.selectedFilter = this.selectedFilter.filter( p=>p !== filter);
		}
		else
		{
			this.selectedFilter.push( filter);
		}
	},
	closeCMN() { try{ this.$refs.menu.close(); } catch(e) { /* */ }},
    getData( selectedFilter)
    {
		this.filterStructure = null;
		let filter = [];
		for ( let flt in selectedFilter )
		{
			filter.push( this.filters.find(p=>selectedFilter[flt]==p.name));
		}
		let summary = [];
		let keyHelper = {};
		let keyHelperGraph = {};
		let graphData = []; 
		
		let myData = this.doFilterSpots(this.data);
		let total = null;
		
		//let counter = 0;
		if ( this.total )
		{
			this.total = null;
		}
		let allSpots = [];
		for ( let iGrid in myData.gridAds)
        {
        	let grid = myData.gridAds[iGrid];
        	if ( grid )
        	{
            	for ( let iBreak in grid.breaks)
            	{
            		let brk = grid.breaks[iBreak];

					for ( let s in brk.spots )
					{
						let spot = brk.spots[ s];
						allSpots.push( spot);
					}
            	}
        	}
        }
		if ( this.allPossibleSpots )
		{
			for ( let s in this.allPossibleSpots )
			{
				let spot = this.allPossibleSpots[ s];
				let found = allSpots.find( s=>s.placementId===spot.placementId);
				if ( !found )
				{
					allSpots.push( spot);
				}
			}
		}
		for ( let s in allSpots)
		{
			let spot = allSpots[ s];
			let counting = (spot.id) > 0 ? 1 : 0;
			// convert currency
			let rate = 1;
			if ( spot.gross4 && spot.grossHC )
			{
				rate = spot.gross4 / spot.grossHC
			}
			else if ( spot.gross3 && spot.grossHC )
			{
				rate = spot.gross3 / spot.grossHC
			}
			
			
			let key = "_";
			let lastKey = null;
			let lastFilter = null;
			for ( let flt in filter )
			{
				key += spot[filter[flt].id]+"_";
				lastKey = spot[filter[flt].id]+"_";
				lastFilter = filter[flt];
			}
			// uniNumber ( convert string-key into int to create an sortable array)
			let uniNumber = keyHelper[ key ];
			if ( isNaN( uniNumber)  )
			{
				keyHelper[ key ] = Object.keys(keyHelper).length;
			}
			if ( lastKey )
			{
				if ( isNaN( keyHelperGraph[ lastKey] ) )
				{
					keyHelperGraph[ lastKey ] = Object.keys(keyHelperGraph).length;
					let lbl = spot[lastFilter.label];
					if (lastFilter.type.toLowerCase()==='boolean' )
					{
						lbl = lbl?'X':'-';
					}
					else if (lastFilter.type.toLowerCase()==='time' )
					{
						lbl = printTimeHHMM( lbl)
					}
					if ( lbl == null )
					{
						if (lastFilter.type.toLowerCase()==='string' )
						{
							lbl = "-";
						}
						else
						{
							lbl = 0;
						}
					}
					
					graphData[ keyHelperGraph[ lastKey ]] = { label: lbl, value: counting };
				}
				else
				{
					graphData[keyHelperGraph[ lastKey ]].value++;
				}
			}
			let info = summary[ keyHelper[ key ] ];
			if ( !info )
			{
				let labels = [];
				for ( let flt in filter )
				{
					let lbl = spot[filter[flt].label];
					if (filter[flt].type.toLowerCase()==='boolean' )
					{
						lbl = lbl?'X':'-';
					}
					else if (filter[flt].type.toLowerCase()==='time' )
					{
						lbl = printTimeHHMM( lbl)
					}
					if ( lbl == null )
					{
						if (filter[flt].type.toLowerCase()==='string' )
						{
							lbl = '-'
						}
						else
						{
							lbl = 0;
						}
					}
					labels.push( { label:lbl, filter: filter[flt].id, value: spot[filter[flt].id]});
					
				}
				
				info = { sample: spot, label: labels, count: counting, vol: spot.duration, grp: spot.grp, gross3: spot.gross3*rate, grossHC: spot.grossHC}
				info.placementId = [spot.placementId];
				summary[ keyHelper[ key ] ] = info;
			}
			else
			{
				info.count += counting;
				if ( !info.placementId.includes(spot.placementId))
				{
					info.placementId.push(spot.placementId);
				}
				info.vol += spot.duration;
				info.grp += spot.grp
				info.grossHC += spot.grossHC
				info.gross3 += spot.gross3*rate
				
			}
			
			if ( total === null)
			{
				total = { label: 'Total', count: counting, vol: spot.duration, grp: spot.grp, gross3: spot.gross3*rate, grossHC: spot.grossHC}
				total.placementId = [spot.placementId];
			}
			else
			{
				total.count += counting;
				if ( !total.placementId.includes(spot.placementId))
				{
					total.placementId.push(spot.placementId);
				}
				total.vol += counting*spot.duration;
				total.grp += spot.grp
				total.grossHC += spot.grossHC
				total.gross3 += spot.gross3*rate
			}
		}
		
		this.total = total;
		this.total.ready = true;
		this.summary = summary;
		this.activeFilter = filter;
		this.sortSummary();
		let labels = graphData.map(p=>p.label)
		let list = graphData.map(p=>p.value)
		
		let col = sessionStorage.fgColor;
        let colors = [];
        for (let i in labels)
        {
        	colors.push( col);
	    }
		// ---------------------------------------
		let chartOptions = {
					  chart: { 
					    type: 'pie',
					    height: "250pt",
					  },
					  labels: labels,

					  series: list,
					  legend: {  labels: { colors: [col]}},
					  markers: {
						  size: [2],
					      colors: [col]
						},
					}
					
            		
	            	this.distroyChart();
					if ( labels )
					{
	            		this.chartOptions = chartOptions;
			            this.chart = new ApexCharts(document.querySelector("#pieChart"), this.chartOptions);
			            this.$nextTick(function () {		
				            this.chart.render();
				            this.$nextTick(function () {
				            	this.$forceUpdate();
				            });
			            });
					}
					
		// ---------------------------------------
		return summary;
    },
    sortSummary()
    {
    	this.summary.sort((a,b)=> this.compare(a,b,this.activeFilter))
    },
    hasFilter( f)
    {
    	return f && (f.children || f.atomicLines)
    },

    distroyChart()
    {
    	if ( this.chart)
		{
    		try
    		{
				this.chart.destroy();
				this.chart = null;
			}
    		catch(e)
    		{
    		//
    		}
		}
    },
    compare( a, b, filter)
    {
    	let comp = 0;
    	if ( this.sort==='spots')
    	{
    		comp = b.count-a.count;
    	}
    	else if ( this.sort==='value')
    	{
    		comp =  b.grossHC-a.grossHC;
    	}
    	else if ( this.sort==='seconds')
    	{
    		comp =  b.vol-a.vol;
    	}
    	if ( comp )
    	{
    		return comp;
    	}
    	for ( let flt in filter )
		{
    		let fKey = filter[flt];
    		let comp = 0;
    		if ( fKey.type.toLowerCase() !== 'numeric' )
			{
    			comp = a.label[flt].label.localeCompare(b.label[flt].label)
    			if ( comp )
    			{
    				return comp;
    			}
			}
    		else
    		{
    			comp = a.label[flt].label-b.label[flt].label
    			if ( comp )
    			{
    				return comp;
    			}
    		}
		}
    	return 0;
    },
    showAllFilters( line)
	{
		let that = this;
		let filters = [...line.label];
		for ( let p in line.placementId)
		{
			filters.push({label: "Placement", filter: "placementId", value: line.placementId[p]})
		}
		
		HTTP.post( inventoryInlineAPI+"/getAllFilterForBookingsRundown/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, filters)
		 .then( response => 
   		{
   			that.filterStructure = response.data;
   			
   		}).catch(e => {
            
            showError( that.$toast, "show filters", e)
    	});
	},
	showAllPlacements()
	{
		let that = this;
		let dateISO = momentTZ(this.date).utcOffset(0, true).format().split('T')[0];
		//alert(bngAPI+"/getVirtualSpotsForDay/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.station.id+"/"+dateISO)
		HTTP.post( bngAPI+"/getVirtualSpotsForDay/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.station.id+"/"+dateISO)
		 .then( response => 
   		{
   			that.allPossibleSpots = response.data;
   			that.getData( that.selectedFilter);
   			
   		}).catch(e => {
            
            showError( that.$toast, "show filters", e)
    	});
	},
	getInvEditorId() {
    	return 'INVEDIT'+this.myID; 
    },
    getInvEditor() {
    	if ( Array.isArray(this.$refs[this.getInvEditorId()]))
		{
			return this.$refs[this.getInvEditorId()][0];
		}
		else
		{
			return this.$refs[this.getInvEditorId()];
		}
    },
  openInvEditor( filter, record, columnName, forceOpen)
  {
  	//alert("GFWE"+JSON.stringify(record)+columnName)
  	this.getInvEditor().open( filter, record, columnName, forceOpen, this.setInventory);
  },
  setInventory( filter)
  {
	  this.filterStructure=filter;
  },
    doReload()
    {
		this.getData(this.selectedFilter);
    },
	getState()
	{
		return this.myData.statusList.find( p => p.shortname === this.selectedState);
	},
	doFilterSpots( data)
    {
        for ( let iGrid in data.gridAds)
        {
        	let grid = data.gridAds[iGrid];
        	if ( grid )
        	{
            	for ( let iBreak in grid.breaks)
            	{
            		let brk = grid.breaks[iBreak];
            		if ( brk )
        			{
        				let tmp = brk.unfilteredSpots;
        				if ( tmp )
        				{
	        				if ( this.selectedPrograms && this.selectedPrograms.length > 0 )
					     	{
					     		tmp =  tmp.filter(spot => this.selectedPrograms.includes(spot.gridName) );
					     	}
					     	if ( this.selectedClients && this.selectedClients.length > 0 )
					     	{
					     		tmp =  tmp.filter(spot => this.selectedClients.includes(spot.clientName) );
					     	}
					     	if ( this.selectedProducts && this.selectedProducts.length > 0 )
					     	{
					     		tmp =  tmp.filter(spot => this.selectedProducts.includes(spot.productName) );
					     	}
							if ( this.selectedRateTypes && this.selectedRateTypes.length > 0 )
					     	{
					     		tmp =  tmp.filter(spot => this.selectedRateTypes.includes(spot.rateType) );
					     	}
					     	if ( this.selectedCopies && this.selectedCopies.length > 0 )
					     	{
					     		tmp =  tmp.filter(spot => this.selectedCopies.includes(spot.copyName) );
					     	}
							if ( this.selectedPG && this.selectedPG.length > 0 )
					     	{
					     		tmp =  tmp.filter(spot => this.selectedPG.includes(spot.pricegroup) );
					     	}
					    }
                		brk.spots = tmp;
            		}	
            	}
            }
        }
		return data;
    },

	set( data)
	{
		if ( !Array.isArray( data))
		{
			this.myData = data;
			this.diff = data.gridAds.diff;
			this.doFilterSpots(this.myData);
			this.allPossibleSpots = [];
			this.selectedState = this.stateName;
			this.statusList = new Array();
			this.selectedStation = {id: this.station.id, label: this.station.name}
			for ( var i in data.statusList )
			{
				this.statusList.push( data.statusList[i].shortname )
			}
			this.getData( this.selectedFilter)
		}
	},
	stateChanged()
	{
		this.$emit("changeState", this.myData.date, this.myData.media.id, this.getState())
	},
	saveAllFilters(filters)
    {
    	let that = this;
    	if ( filters.placementFilter )
    	{
    		delete filters.placementFilter.mediaKindId;
    	}
    	if ( filters.clientFilter )
    	{
    		delete filters.clientFilter.mediaKindId;
    	}
    	if ( filters.productFilter )
    	{
    		delete filters.productFilter.mediaKindId;
    	}
    	HTTP.put( inventoryInlineAPI+"/saveAllFilterForBookings/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId, filters)
		 .then( response => 
  		{
  			that.filterStructure = response.data;
  			that.$toast.success("Filters saved", 'Ok', { timeout: 1000, position: "topRight" });
  			return that.filterStructure;
  		}).catch(e => {
           
           showError( that.$toast, "saveAllFilters", e)
        });
    },
  },
  updated() {
    
	 
	  if ( this.chart && this.chartOptions && this.chartOptions.labels && this.chartOptions.labels.length)
		{
		    this.distroyChart();
			this.chart = new ApexCharts(document.querySelector("#pieChart"), this.chartOptions);
	    	this.chart.render();
		}
  },
  created() { 
   
	this.set( this.data);
	
  },
  watch: {
    stateName: function() {
		this.set( this.data);
	},
	selectedFilter: function() {
		this.getData( this.selectedFilter)
	},
	data: function() {
		this.set( this.data);
	},
	date: function() {
		
		this.myDate = this.date;
		this.set( this.data);
	},
	selectedPrograms: function() {
		this.set( this.data);
	},
	selectedCopies: function() {
		this.set( this.data);
	},
	selectedClients: function() {
	   	this.set( this.data);
	},
	selectedProducts: function() {
	    this.set( this.data);
	},
	selectedRateTypes: function() {
		this.set( this.data);
	},
	selectedPG: function() {
		this.set( this.data);
	},
	update: function() {
	    this.set( this.data);
	}
  },
 }
</script>

<style scoped>

.invisible {
	display: none;
}
.transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.transitionFast {
 	transition: all .2s;
   -webkit-transition: all .2s;
}
.transitionOptimizer {
  transition: all .8s;
   -webkit-transition: all .8s;
}
.settingsBlock {
    position: relative;
    display: block;
    margin-top: 4pt;
	padding-top: 10pt;
	padding-bottom: 2pt;
	height: calc(30vH);
	border-top: 1px solid grey;
}
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.SVcontrolLabel {
	font-weight: bold;
	font-size: 10pt;
	padding-top: 9pt;
}
.RDHeader {
    display: inline;
	width: 99% !important;
	font-size: 12px;
	height: 100% !important;
	font-weight: bold;
}
.RDHeaderText {
	padding-top: 3pt;
	padding-left: 2pt;
}
.RDProgramTitle {
    display: inline-flex;
    padding-left: 5pt;
	font-size: 14px;
	font-weight: bold;
	background-color: #f8f8f8;
}
.RDProgramTime {
    display: inline-flex;
	font-size: 14px;
	width: 120px;
	color: #444;
	font-weight: bold;
	background-color: #f8f8f8;
}
.RDProgramTime2 {
    display: inline-flex;
	font-size: 14px;
	padding-left: 10pt;
	color: #888;
	font-weight: bold;
	background-color: #f8f8f8;
}
.RDProgram {
	font-size: 14px;
	font-weight: bold;
	background-color: #f8f8f8;
}
.RDBreakTitle {
	
	font-weight: bold;
	
}
.RDBreakTime {
    display: inline-flex;
	width: 120px;
	color: #444;
	font-weight: bold;
	background-color: #f8f8f8;
}
.RDBreakTime2 {
    display: inline-flex;
	padding-left: 10pt;
	color: #888;
	font-weight: bold;
	background-color: #f8f8f8;
}
.RDBreak {
	font-size: 11px;
	font-weight: bold;
	background-color: #f8f8f8;
	border-top: 2px outset #ddd;
	text-align: left;
	padding-left: 6pt;
}
.RDOpenProgram {
    margin-top: 2pt;
    margin-bottom: 8pt;
    padding: 2px;
	border-top: 2px outset #ddd;
	border-left: 1px outset #888;
	border-bottom: 1px outset #ccc;
}
.myButton {
	//width: 18%;
    padding: 2px 2px 2px 2px !important;
    outline: none;
    border-radius: 3px;
    height: 20pt;
    font-size: 11px !important;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 2pt;
}
.invers {
	padding-left: 8pt; 
	padding-right: 4pt;
	width: 100%; 
	height: 18pt;
	padding-top: 1pt;
	color: #000; 
	background-color: #ccc;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 78% !importrant;
  display: flex;
}
.selected {
	background-color: #888 !important;
	color: #fff !important;
}
.fxTableHeader { 
  align-items: center;
  justify-content: center;
  font-size: 10px !important; 
  position: sticky; 
  top: 0px;
  border: none !important;
  background-color: #bbf; 
  color: #000;
}
.noOverflow {
  overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.fixed_header {
  width: 100%;
  font-size: 11px;
  table-layout: fixed;
  
}
.fixed_header tbody {
  display: block;
  width: 100%;
  
  overflow: auto;
  height: 200pt;
}
.fixed_header thead tr {
  display: block;
}
.fixed_header thead {
  background: white;
  color: #000;
  overflow: auto;
}
.sorter {
	height: 14pt;
	font-size: 8pt;
}
.xfixed_header th
{
	
	max-width: calc(10vH) !important;
}
.rightAlign {
  text-align: right;
}
.xfixed_header td {
  max-width: calc(10vH) !important;
  padding: 1px;
  overflow: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  border-right: 1px solid grey;
  border-bottom: 1px solid grey;
}
.selectable {
}
.selectable:hover {
  cursor: pointer;
  background: #aaa;
  color: #fff;
}
.numeric {
text-align: right;
}
.total {
margin-top: 3pt;
font-weight: bold;
border-bottom: 2px solid grey;
}
.smallTextFull {
  font-size: 11px;
  height: 14pt;
  padding-top: 3pt;
  display: inline-block;
}
</style> 