<template>
<div>
    
    <div style='display: flex; width: 99.8% !important;' class='RDHeader' :style='getHeaderStyle()'>
        <div style='display: flex; width: 98% !important;' :style='getHeaderStyle()'>
           <div style='width:18%'>
	        <InputDatePick :auto='false' style='font-size: 9pt; width:100px; height:28px;' @change="dateChanged" v-model="date"/>
	       </div>
	        <vSelect placeholder='choose channel' :options="stations" style='width:100%' v-model="selectedStation" @input='stationChanged'> 
		    </vSelect>
	    	

		 </div>
		<div class='RDHeaderText' style='text-align:right; width: 12%; padding-right: 5pt;' >
		<svg @click="$emit('closeDay', dayId)" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-x-square" viewBox="0 0 16 16">
				  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
				  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
				</svg>
		</div>
    </div>
    
	<div :style="getAppStyle()+'width: 100%; position: relative; font-size: 12px; height: calc(82vH) !important; overflow-y; scroll;'">
	 	<div :style="getAppStyle()+'width: 100%; position: relative; font-size: 12px;'">
	 	
	 	        <div style="color: #000;" id='aChart' />
		       
		 </div>
		
		 <div :style="'height: calc(45vH) !important; position: relative;overflow-x: hidden;display: flex; overflow-y; scroll;'">
		 
		 <div v-if="regulations.length">
			 <div style="float: right; display: block; top: 0; position: sticky; z-index: 9;">
			 <button title='Refresh/Reload all data' class='button myButton' :style='getAppStyle()' @click="reload()"><mdicon :width="16" name="reload" /></button>
		     </div> 
		 	<div v-for="(r,ri) in regulations" :key="'r'+ri">
		 	    <div v-if="r.levelValue == 'perHour'">
		 	    	<div :style="getAppStyle()+'position: sticky; top: 0px;'" class='regulation' @click="$refs.editor.open('AllowedAdTimeDetail', r.id)">
		 	    	{{r.name}}<span v-if="r.typeValue=='blocker'"> (BLOCKER RULE)</span></div>
		 	    	<div v-for="(hu,hui) in graph.labels" :key="'rh'+hui" style="display: inline-block; width: 100%;">
		 	    	<div style="display: inline-flex; padding: 0pt; margin-top:2pt; height: 14pt; width: 100%; white-space: nowrap;">
			 	    	<div style=" font-size: 9pt; margin-left:8pt; display: inline-flex; width: 14ex;">{{printTime2(hu*3600)}}</div>
			 	    	<GProgress :height="12" :width='200' :treshold="r.treshold" :percentage="((100*hourUsage[hu]*60)/r.adTimeISOInSeconds)"/>
			 	    	<div v-if="hourUsage[hu]" style="font-size: 9pt; margin-left: 10pt; display: inline-flex; width: 20%;">
			 	    	   <apan style='width: 8ex;'>{{Math.round(hourUsage[hu]*100)/100}}min</apan> / {{Math.round(100*r.adTimeISOInSeconds/60)/100}}min
			 	    	</div>
		 	    	</div>
		 	    	<br/>
		 	    	</div>
		 	    	
		 	    </div>
		 	   <div v-if="r.levelValue == 'perDay'">
		 	    	<div :style="getAppStyle()+'position: sticky; top: 0px;'" class='regulation' @click="$refs.editor.open('AllowedAdTimeDetail', r.id)">
		 	    	{{r.name}}<span v-if="r.typeValue=='blocker'"> (BLOCKER RULE)</span></div>
		 	    	
		 	    	<div style="display: inline-flex; width: 100%; white-space: nowrap;">
			 	    	<div style="font-size: 9pt; margin-left:8pt; display: inline-flex; width: 14ex;">day</div>
			 	    	<GProgress :height="12" :width='200' :treshold="r.treshold" :percentage="((100*60*dayUsage)/r.adTimeISOInSeconds)"/>
			 	    	<div v-if="dayUsage" style="font-size: 9pt; margin-left: 10pt; display: inline-flex; width: 20%;">
			 	    	   <apan style='width: 8ex;'>{{Math.round(100*dayUsage)/100}}min</apan> / {{Math.round(100*r.adTimeISOInSeconds/60)/100}}min
			 	    	</div>
		 	    	</div>
		 	    	
		 	    	
		 	    </div>
		 	    <div v-if="r.levelValue == 'individual'">
		 	    	<div :style="getAppStyle()+'position: sticky; top: 0px;'" class='regulation' @click="$refs.editor.open('AllowedAdTimeDetail', r.id)">
		 	    	{{r.name}}<span v-if="r.typeValue=='blocker'"> (BLOCKER RULE)</span></div>
		 	    	
		 	    	<div style="display: inline-flex; width: 100%; white-space: nowrap;">
			 	    	<div style="font-size: 9pt; margin-left:5pt; display: inline-flex; width: 14ex;">{{printTime2(r.starttime)}}-{{printTime2(r.endtime)}}</div>
			 	    	<GProgress :height="12" :width='200' :treshold="r.treshold" :percentage="(100*indUsage[r.id]/r.adTimeISOInSeconds)"/>
			 	    	<div v-if="indUsage[r.id]" style="font-size: 9pt; margin-left: 10pt; display: inline-flex; width: 20%;">
			 	    	   <apan style='width: 8ex;'>{{Math.round(indUsage[r.id]/60*100)/100}}min</apan> / {{Math.round(100*r.adTimeISOInSeconds/60)/100}}min
			 	    	</div>
		 	    	</div>
		 	    	
		 	    	
		 	    </div>
		 		</div>
		 	</div>
		 </div>
				
	 </div>
	 <GFWEOpenEditor ref='editor' @update='doReload'></GFWEOpenEditor>
	
</div>
</template> 
<script>
import {HTTP, fwAPI, invAPI, setReload, myLocale, showError} from '@/variables.js';
import {fmtTimePart, printTimeOpt, printTime2, getHour } from '@/basicTimeFN.js';
import { getAppStyle, setDarkMode, initAppMode, hexToRgb, isDark } from '@/AppStyle.js';
import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import InputTimezone from '@/components/inputElements/InputTimezone';
import SimpleSpotList from '@/components/SimpleSpotList';
import InputDatePick from '@/components/inputElements/InputDatePick2'; 
import vSelect from 'vue-select'
import GProgress from '@/components/misc/GProgress';
import 'vue-select/dist/vue-select.css';
import ApexCharts from 'apexcharts'
export default {
  name: 'GFWRunDownViewChart',
  components : {
   vSelect, InputDatePick
   , GProgress
  },
  props: {
    station: Object,
    stations: Array,
    date: Date,
    showEmptyBreaks: Boolean,
    showDangling: Boolean,
	selectedRateTypes: Array,
    selectedPrograms: Array,
    selectedClients: Array,
    selectedProducts: Array,
    selectedCopies: Array,
	selectedPG: Array,
    stateName: String,
    timezone: Object,
    update: Number,
    dayId: String,
    data: Object
  },
  data () {
    return {
      myData: {},
      graph: {},
      diff: 0,
      regulations: [],
      hourUsage: [],
      dayUsage: 0,
      indUsage: [],
      selectedState: {},
      selectedStation: {},
      statusList: [],
      controlsRight: false,
      getAppStyle,
      chart: null,
      chartOptions: null,
      printTime2
    }
  },
  methods: {
    myLocale() { return myLocale();},
    getRawKey(brk)
     {
    	return brk.gridId+":"+brk.mediaId+":"+brk.breakNo+":"+brk.breakTypeId+":"+brk.date;
     },
    getRef(idx1,idx2) { return "SSL_"+idx1+"."+idx2;},
    getHeaderStyle() {
    	if ( this.selectedState )
    	{
    		return "background-color: "+this.getState().colour+";";
    	}
    	return "";
    },
    reload()
    {
		this.set( this.data);
	},
	dateChanged(date) {
		this.$emit("dateChanged", this.dayId, date)
	},
	stationChanged(selectedStation) {
		this.$emit("stationChanged", this.dayId, selectedStation.id)
	},

    doReload()
    {
		this.getGraphData( this.data)
    },
	getState()
	{
		return this.myData.statusList.find( p => p.shortname === this.selectedState);
	},
	doFilterSpots( data)
    {
     
        for ( var iGrid in data.gridAds)
        {
        	let grid = data.gridAds[iGrid];
        	if ( grid )
        	{
            	for ( var iBreak in grid.breaks)
            	{
            		let brk = grid.breaks[iBreak];
            		if ( brk )
        			{
        				let tmp = brk.unfilteredSpots;
        				if ( tmp )
        				{
	        				if ( this.selectedPrograms && this.selectedPrograms.length > 0 )
					     	{
					     		tmp =  tmp.filter(spot => this.selectedPrograms.includes(spot.gridName) );
					     	}
					     	if ( this.selectedClients && this.selectedClients.length > 0 )
					     	{
					     		tmp =  tmp.filter(spot => this.selectedClients.includes(spot.clientName) );
					     	}
					     	if ( this.selectedProducts && this.selectedProducts.length > 0 )
					     	{
					     		tmp =  tmp.filter(spot => this.selectedProducts.includes(spot.productName) );
					     	}
							if ( this.selectedRateTypes && this.selectedRateTypes.length > 0 )
					     	{
					     		tmp =  tmp.filter(spot => this.selectedRateTypes.includes(spot.rateType) );
					     	}
							if ( this.selectedPG && this.selectedPG.length > 0 )
					     	{
					     		tmp =  tmp.filter(spot => this.selectedPG.includes(spot.pricegroup) );
					     	}
					     	if ( this.selectedCopies && this.selectedCopies.length > 0 )
					     	{
					     		tmp =  tmp.filter(spot => this.selectedCopies.includes(spot.copyName) );
					     	}
					    }
                		brk.spots = tmp;
            		}	
            	}
            }
        }
		
    },
    getRegulations()
    {
    	let that = this;
    	let dataLoad = new Promise(function (resolve, reject) 
       	{

        	HTTP.post( invAPI+"/getRegulations/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.station.id)
                    .then( response => 
                    {
                    	that.regulations = response.data;
                    	
                    	resolve( response.data)
                    }).catch(e => {
              	      
                        showError( that.$toast, e, "can't get regulatons");
                        reject();
                    });
    	});
    	return dataLoad;
    },
    distroyChart()
    {
    	if ( this.chart)
		{
    		try
    		{
				this.chart.destroy();
				this.chart = null;
			}
    		catch(e)
    		{
    		//
    		}
		}
    },
    getGraphData( data)
    {
    	this.getRegulations().then( r => {
    		this.regulations = r;
    		let regIndi = r.filter( p=>p.levelValue=='individual');
    		let hours = new Array();
            this.graph.labels = new Array();
            this.graph.seriesList = new Array();
            this.indUsage = [];
            
            for ( var iGrid in data.gridAds)
            {
            	let grid = data.gridAds[iGrid];
            	if ( grid )
            	{
                	for ( let iBreak in grid.breaks)
                	{
                		let brk = grid.breaks[iBreak];
                		if ( brk )
            			{
            				let h = getHour(brk.brk.time)
            				for ( let ri in regIndi )
            				{
            					let r = regIndi[ri];
            					
            					if ( r.starttime <= brk.brk.time && r.endtime > brk.brk.time)
            					{
            						if ( !this.indUsage[r.id])
            						{
            							this.indUsage[r.id] = 0;
            						}
            						if ( brk.spots)
                    				{
            							this.indUsage[r.id] += brk.spots.map(s=>s.duration).reduce((pv, cv) => pv + cv, 0);
            						}
            						
            					}
            				}
            				if ( !hours[h])
                    		{
                    			hours[h]=0;
                    		}
                    		if ( ! this.graph.labels.includes(h))
                    		{
                    			this.graph.labels.push(h);
                    		}
            				if ( brk.spots)
            				{
	                    		var val = brk.spots.map(s=>s.duration).reduce((pv, cv) => pv + cv, 0);
	                    		hours[h] += val/60;
            				}
                		}	
                	}
                }
                
            }
            var list = new Array();
            this.hourUsage = hours;
           
            this.dayUsage = hours.filter(p=>p).reduce((pv, cv) => pv + cv, 0);
            
            for ( var hh in this.graph.labels )
            {
            	list.push(  Number( hours[this.graph.labels[hh]].toPrecision(2) ));
            }
            let col = sessionStorage.fgColor;
            let colors = [];
            for (let i in this.graph.labels)
            {
            	colors.push( col);
    	    }
            let chartOptions = {
					  chart: { 
					    type: 'bar',
					    height: "300pt",
					  },
					  labels: this.graph.labels,
					  stroke: {
						  show: true,
				    	  curve: 'smooth',
				    	  width: 2
				    	},
				    	title: {
			                text: 'minutes per hour',
			                align: 'center',
			                style: {
			                  fontSize: '18px',
			                  fontWeight: 'bold',
			                  color: col,
			                },
			              },
					  series: [{ "name": "Minutes", "data": list }] ,
					  plotOptions: {
						  pie: { customScale: 0.5 }
					  },
					  dataLabels: {
						  enabled: true
					  },
					  
					  legend: {  labels: { colors: ['#000']}},
					  markers: {
						  size: [2],
					      colors: [col]
						},
					 
					  xaxis: {
					    categories: this.graph.labels,
					    forceNiceScale: true,
					    labels: { style: {colors: colors}}
					  },
					  yaxis: {
		  					forceNiceScale: true,
		  			        decimalsInFloat: 2,
		  			        labels: { style: {colors: [col]}}
		  			  },
					  theme: {
						  palette: "palette1"// upto palette10
						}
					}
					
            		
	            	this.distroyChart();
            		this.chartOptions = chartOptions;
		            this.chart = new ApexCharts(document.querySelector("#aChart"), chartOptions);
							
		            this.chart.render();
            
            //this.graph.seriesList.push( { "name": "Minutes", "data": list } );
            this.$forceUpdate()
    	});

    },
	set( data)
	{
		if ( !Array.isArray( data))
		{
			this.myData = data;
			this.diff = data.gridAds.diff;
			this.doFilterSpots(this.myData);
			this.getGraphData( this.myData);
			this.selectedState = this.stateName;
			this.statusList = new Array();
			this.selectedStation = {id: this.station.id, label: this.station.name}
			for ( var i in data.statusList )
			{
				this.statusList.push( data.statusList[i].shortname )
			}
		}
	},
	stateChanged()
	{
		this.$emit("changeState", this.myData.date, this.myData.media.id, this.getState())
	}
  },
  updated() {
	//this.distroyChart();
	//this.chart = new ApexCharts(document.querySelector("#aChart"), this.chartOptions);
	if ( this.chart )
	{
		this.distroyChart();
		this.chart = new ApexCharts(document.querySelector("#aChart"), this.chartOptions);
    	this.chart.render();
	}
    this.$nextTick(function () {
  	this.$refs.menu.open(event, {spot: {}, name: "" });
	this.closeCMN();
	});
  },
  created() { 
   
	this.set( this.data);
	this.$nextTick(function () {
  	this.$refs.menu.open(event, {spot: {}, name: "" });
	this.closeCMN();
	});
  },
  watch: {
    stateName: function() {
		this.set( this.data);
	},
	data: function() {
		this.set( this.data);
	},
	date: function() {
		this.set( this.data);
	},
	selectedPrograms: function() {
		this.set( this.data);
	},
	selectedCopies: function() {
		this.set( this.data);
	},
	selectedClients: function() {
	   	this.set( this.data);
	},
	selectedProducts: function() {
	    this.set( this.data);
	},
	selectedRateTypes: function() {
		this.set( this.data);
	},
	selectedPG: function() {
		this.set( this.data);
	},
	update: function() {
	    this.set( this.data);
	}
  },
 }
</script>

<style scoped>

.invisible {
	display: none;
}
.transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
.transitionFast {
 	transition: all .2s;
   -webkit-transition: all .2s;
}
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.SVcontrolLabel {
	font-weight: bold;
	font-size: 10pt;
	padding-top: 9pt;
}
.RDHeader {
    display: inline;
	width: 99% !important;
	font-size: 12px;
	height: 100% !important;
	font-weight: bold;
}
.RDHeaderText {
	padding-top: 3pt;
	padding-left: 2pt;
}
.RDProgramTitle {
    display: inline-flex;
    padding-left: 5pt;
	font-size: 14px;
	font-weight: bold;
	background-color: #f8f8f8;
}
.RDProgramTime {
    display: inline-flex;
	font-size: 14px;
	width: 120px;
	color: #444;
	font-weight: bold;
	background-color: #f8f8f8;
}
.RDProgramTime2 {
    display: inline-flex;
	font-size: 14px;
	padding-left: 10pt;
	color: #888;
	font-weight: bold;
	background-color: #f8f8f8;
}
.RDProgram {
	font-size: 14px;
	font-weight: bold;
	background-color: #f8f8f8;
}
.RDBreakTitle {
	
	font-weight: bold;
	
}
.RDBreakTime {
    display: inline-flex;
	width: 120px;
	color: #444;
	font-weight: bold;
	background-color: #f8f8f8;
}
.RDBreakTime2 {
    display: inline-flex;
	padding-left: 10pt;
	color: #888;
	font-weight: bold;
	background-color: #f8f8f8;
}
.RDBreak {
	font-size: 11px;
	font-weight: bold;
	background-color: #f8f8f8;
	border-top: 2px outset #ddd;
	text-align: left;
	padding-left: 6pt;
}
.RDOpenProgram {
    margin-top: 2pt;
    margin-bottom: 8pt;
    padding: 2px;
	border-top: 2px outset #ddd;
	border-left: 1px outset #888;
	border-bottom: 1px outset #ccc;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}
.invers {
	padding-left: 8pt; 
	padding-right: 4pt;
	width: 100%; 
	height: 18pt;
	padding-top: 1pt;
	color: #000; 
	background-color: #ccc;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 78% !importrant;
  display: flex;
}
.regulation
{
    font-size: 10pt;
    color: #88d !important;
    margin-left: 2pt;
    margin-top: 6pt;
    border-top: 1px solid grey;
	cursor: pointer;
	font-weight: bold;
	
}
.regulation:hover
{
	background-color: #888;
	color: #ddd;
}
</style> 