<template>
<div>
<div style='display: inline-block; border: 1pt solid #888; margin:0; padding: 0; width: 83px; height: 16px !important;'>

	<progress-bar style='margin-top: -9px; margin-left: 0px;'
      :options="getOptions(percentage)"
      :value="Math.round(100*percentage)/100"
      />
</div>
</div>
</template>
<script>

export default {
  props: {
  	percentage: { type: Number, default: 0.0 },
  	treshold: { type: Number, default: 0.0 }
  },
 components : {
    
  },
  data () {
    return {
      PBOptions: {
		  text: {
			color: '#000',
			shadowEnable: false,
		    fontSize: 9,
		    fontFamily: 'Helvetica',
		    dynamicPosition: false,
		    hideText: false
		  },
		  progress: {
		    color: '#6d6',
		    backgroundColor: '#ddd'
		  },
		  layout: {
		    height: 14,
		    width: 80,
		    verticalTextAlign: 71,
		    horizontalTextAlign: 33,
		    zeroOffset: 0,
		    strokeWidth: 0,
		    progressPadding: 0,
		    type: 'line'
		  }
		 },
		 PBOptionsRed: {
		  text: {
			color: '#eee',
			shadowEnable: false,
		    fontSize: 9,
		    fontFamily: 'Helvetica',
		    dynamicPosition: false,
		    hideText: false
		  },
		  progress: {
		    color: '#f66',
		    backgroundColor: '#ddd'
		  },
		  layout: {
		    height: 14,
		    width: 80,
		    verticalTextAlign: 71,
		    horizontalTextAlign: 33,
		    zeroOffset: 0,
		    strokeWidth: 0,
		    progressPadding: 0,
		    type: 'line'
		  }
		 },
		 PBOptionsRed2: {
		  text: {
			color: '#000',
			shadowEnable: false,
		    fontSize: 9,
		    fontFamily: 'Helvetica',
		    dynamicPosition: false,
		    hideText: false
		  },
		  progress: {
		    color: '#f66',
		    backgroundColor: '#ddd'
		  },
		  layout: {
		    height: 14,
		    width: 80,
		    verticalTextAlign: 71,
		    horizontalTextAlign: 33,
		    zeroOffset: 0,
		    strokeWidth: 0,
		    progressPadding: 0,
		    type: 'line'
		  }
		 }
    }
  },
  methods: {
	  getOptions(percentage) {
		  if ( percentage > 100 )
		  {
			  return this.PBOptionsRed;
		  }
		  if ( this.treshold && percentage < this.treshold)
		  {
			  return this.PBOptionsRed2;
		  }
		  return this.PBOptions;
	  }
  }
 }
</script>
<style lang="scss" scoped>

</style>