<template>
	<div>
		<div class='dontWrap invers bold' :style="getAppStyle()+'padding-left: 5pt; padding-top: 3pt; height: 24pt !important; background-color: '+getBGColor('#ccc','#666')+' !important; font-size: 15px; height: 22pt; width: 100%; margin-bottom: 15px;'"> 
			    <div :style="getAppStyle()+'float: left; display: flex; background-color: transparent; vertical-align: bottom;'"  class='invers bold'>
					<mdicon :width="16" name="upload" />  
					<span v-if="uploadData.media">	
						
						<b v-if="stationId && stationId==uploadData.media.id" >{{ uploadData.media.name}}</b> 
						<b v-else style='color: red;'>{{ uploadData.media.name}}</b> 
						<span class="small" v-if="uploadData.day && uploadData.day.breaks.length">	
							({{ uploadData.day.dayGridImport.name }}) - {{ uploadData.txDateFrom}}-{{ uploadData.txDateTo}}, {{ uploadData.dayLines.length}} breaks
						</span>	
					</span>
					<span v-else>
						day-break-file upload ({{activeDate}})
					</span>	
				</div>	
				<WindowCloseBN style='float: right; padding-top: 1pt;' @click="$emit('closeDay', dayId)"/>
			</div>
		<div class='uploadArea transition' id='uploader'
	        :style="getAppStyle()+'display: block; height: calc(100vH - 160px); text-align: center; vertical-align: middle; align-items: center; justify-content: center; border: 2pt solid #fcd;'"
	  		 @drop="dropFile($event, 0, 0, 'uploader');"
	   	     @dragenter.prevent="hoverLine( 'uploader')"
	   	     @dragleave.prevent="leaveLine( 'uploader')"		        
	   		 @dragover.prevent="hoverLineMain( 'uploader')" >
			 <div v-if="uploadData && uploadData.media" style="display: block; width: 100%; height: calc(100vH - 150px); justify-content: none; text-align: left; align-items: left !important;">
				 <div style='display: block; width: 100%; height: 18pt; '>		
					 <div style='display: inline-flex; float: left;'>		
						 <button :disabled="!uploadData.day.breaks.length" title='' class='button myButton' :style="getAppStyle()+'flow: left;'" @click="importDayGrid"><mdicon :width="16" name="import" />Import</button>
						 <button v-if="formDataSave" title='' class='button myButton' :style="getAppStyle()+'flow: left;'" @click="reloadFile()"><mdicon :width="16" name="reload" />Reload</button>
					 </div>
					 <div style='display: inline-flex; float: right !important; margin-top: 5pt;'>
					 	Follow break: <app-switch v-model="showBreaks" :checked="showBreaks" />
					 </div>
				 </div>
				 <br/>
		         <div style='display: block; width: 100%; flow: left;'>
					 <button title='' class='button tab' :style="type=='matched'?invers():getAppStyle()" @click="type='matched'">
						Matched<span v-if="uploadData.day.breaks.length" class='w3-badge w3-blue'>{{uploadData.day.breaks.length}}</span></button>
					 <button title='' class='button tab' :style="type=='missing'?invers():getAppStyle()" @click="type='missing'">
					    Missing<span v-if="uploadData.day.missing.length" class='w3-badge w3-blue'>{{uploadData.day.missing.length}}</span></button>
					 <button title='' class='button tab' :style="type=='additional'?invers():getAppStyle()" @click="type='additional'">
					    Additional<span v-if="uploadData.day.additional.length" class='w3-badge w3-blue'>{{uploadData.day.additional.length}}</span></button>
				 </div>
			<div style="font-size: 10pt; height: calc(100vH - 250px); justify-content: none; text-align: left; align-items: left !important; overflow-y: auto;">
				<div :style="'position: sticky; top: 0; font-size: 11pt; font-weight: bold;'+getAppStyle()">
					<div class="breakVal">Time</div> 
					<div class="breakVal2">Code (Day)</div>
					<div class="breakVal2">Code (Grid)</div>
					<div class="breakValL">Program</div> 
					<div class="breakVal right">Spots</div> 
					<div class="breakVal right">Duration</div> 
				</div>	
			<div v-if="type=='matched'">	
				<div v-for="(d,di) in uploadData.day.breaks" :key="'d'+di" class="breakLine" :id="'BR_'+di"					
							@mouseenter="hoverLine('BR_'+di, d.breakGrid.name)"
							@mouseleave="leaveLine('BR_'+di)">
					<div class="breakVal">{{printTime2(d.line.breakTime)}}</div> 
					<div class="breakVal2">{{d.dayBreak.code}}</div> 
					<div class="breakVal2">{{d.breakGrid.name}}</div> 
					<div class="breakValL">{{d.line.program}}</div> 
					<div class="breakVal right">{{d.spots.length?d.spots.length:"-"}}</div> 
					<div class="breakVal right">{{printTimeMMSS(d.dayBreak.capacityInMS/1000)}} </div> 
					   
				</div>
			</div>
			<div v-else-if="type=='missing'">	
				<div v-for="(d,di) in uploadData.day.missing" :key="'d'+di" class="breakLine" :id="'BR_'+di"					
							@mouseenter="hoverLine('BR_'+di,  d.breakGrid.name)"
							@mouseleave="leaveLine('BR_'+di)">
					<div class="breakVal">{{printTime2(d.dayBreak.time)}}</div> 
					<div class="breakVal2">{{d.dayBreak.code}}</div> 
					<div class="breakVal2">{{d.breakGrid.name}}</div> 
					<div class="breakValL">{{d.dayGrid.program}}</div> 
					<div class="breakVal right">{{d.spots.length?d.spots.length:"-"}}</div> 
					<div class="breakVal right">{{printTimeMMSS(d.capacityInMS/1000)}}</div> 
					   
				</div>
			</div>
			<div v-else-if="type=='additional'">	
				<div v-for="(d,di) in uploadData.day.additional" :key="'d'+di" class="breakLine" :id="'BR_'+di"					
							@mouseenter="hoverLine('BR_'+di)"
							@mouseleave="leaveLine('BR_'+di)">
					<div class="breakVal">{{printTime2(d.line.breakTime)}}</div> 
					<div class="breakVal2">{{d.dayBreak.code}}</div> 
					<div class="breakVal2">{{d.breakGrid.name}}</div> 
					<div class="breakValL">{{d.line.program}}</div> 
					<div class="breakVal right">-</div> 
					<div class="breakVal right">{{printTimeMMSS(d.line.capacityBreak)}} </div> 
					   
				</div>
			</div>
			</div>
		  </div>
		  <div v-else-if="showDropzone" style='margin-top: 50pt; color: #aaa;'>drop break file</div>
	   </div>
	<GConfirm ref='uplConfirm' name="uplConfirm"/>
	<ProgressBarUPL v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBarUPL>
	
</div>
</template>
<script>

import { getAppStyle, setDarkMode, getBGColor, initAppMode, invers, getDefaultColorStyle } from '@/AppStyle.js';
import {HTTP, HTTPMP, bngAPI, fwAPI, invAPI, gridAPI, userAPI, myLocale, showError} from '@/variables.js';
import {store, restore} from '@/runDown.js';
import { printTime2, printTimeMMSS } from '@/basicTimeFN.js';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import RunDown from '@/components/RunDown';
import { tr } from '@/translate.js';
import RunDownChart from '@/components/RunDownChart';
import RunDownChartClients from '@/components/RunDownChartClients';
import RunDownClipboard from '@/components/RunDownClipboard';
import GSelect from '@/components/misc/GSelect';
import GConfirm from '@/components/GConfirm';
import Switch from '@/components/Switch';
import ProgressBarUPL from '@/components/ProgressBar';
import 'w3-css/w3.css';
var momentTZ = require('moment-timezone')
var timers = [];
import JQuery from "jquery";
let $ = JQuery;
var numeral = require('numeral');
export default {
  props: {
	activeDate: {type: String},
	stationId: {type: Number}
  },
  data () {
	    return {
	    	focus: false,
			showDropzone: true,
			loadingActive: false,
			uploadData: {},
			type: "",
			showBreaks: false,
			formDataSave: null,
			// ProgBar
		      pbFromGridId: 0,
		      pbToGridId: 0,
		      pbHeaderId: 0,
		      pbAction: "",
		      pbData: {},
		      pbTitle: "",
		      showProgressBar: false,
	    	getAppStyle, printTime2, printTimeMMSS, getBGColor, invers, getDefaultColorStyle
	    }
  },
  components : {
    GConfirm, ProgressBarUPL, WindowCloseBN, 'app-switch': Switch
  },
  methods: {
	 hoverLine(line, code)
	 {
	 	$('#'+line).addClass('reqLineBG2');
		if ( code && this.showBreaks)
		{
			this.$emit("selectBreak", code)
		}
	 },
	 hoverLineMain(line)
	 {
	 	//$('#'+line).addClass('reqLineBG');
	 },
	 leaveLine(line)
	 {
	 	$('#'+line).removeClass('reqLineBG2');
	 },
	 dropFile($event, line, rowIdx, lineREF)
	    {
	      let that = this;
	      event.preventDefault();
	      event.stopPropagation();
	     
	      this.showDropzone = false;
	      const files = event.dataTransfer.files;
	      const itemArray = [...files];
	      
	      const item = itemArray.find((i) => true);
	      this.$refs.uplConfirm.confirm( {text: 'Load Gridfile: ' + item.name, title: "Upload Grid", button1: "Cancel", button2: "Ok"}).then(x => {
	    	 
	    	  let check =  new Promise(function (resolve, reject) {
		       	  that.leaveLine(lineREF);
			      if ( item)
				  { 
					  const formData = new FormData();
					  formData.append('file', item);      
					  // that.upload(formData, line, rowIdx, item.name);
					  that.formDataSave = { form: formData, name: item.name };
					  that.pbAction = "uploadFileInternal";
					  that.pbTitle = "Load file";
					  that.showProgressBar = true;
				  }
	
			   });
	      });
	    },
		reloadFile() 
	    {
			if ( this.formDataSave)
			{
				this.pbAction = "uploadFileInternal";
			    this.pbTitle = "Load file";
			    this.showProgressBar = true;
			}
		},	
		uploadFileInternal(myId, api) 
	    {
	      let that=this;
	      let name = this.formDataSave.name;
		  let formData = this.formDataSave.form;

	      HTTPMP.put( gridAPI+"/importDayFile/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.activeDate+"/"+myId, formData)
	        .then( response => 
	        { 
				that.uploadData = response.data;
				that.type='matched';
	            this.showProgressBar = false;
	            that.$toast.success("File '"+name+"' uploaded'", 'Ok', { timeout: 1500, position: "topRight" });
	            that.$forceUpdate();
	      }).catch((err) => {showError( that.$toast, "File '"+name+"' not uploaded", err); this.showProgressBar = false;})

	    },
		action( what, myId, api)
		{
			this[what](myId, api)
		},
		importDayGrid( )
	    {
	    	this.pbHeaderId = this.uploadData.header.id;
	        this.pbAction = "importDayGridInternal";
	        this.pbTitle = "Import as day grid";
	 	    this.showProgressBar = true;
	    },
	    importDayGridInternal(myId, api)
	    {
	    	let that = this;
			//alert( gridAPI+"/importDayGrid/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.uploadData.fileId+"/"+this.activeDate+"/"+this.pbHeaderId+"/"+myId )
	    	api.put( gridAPI+"/importDayGrid/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+this.uploadData.fileId+"/"+this.activeDate+"/"+this.pbHeaderId+"/"+myId)
	        .then( response => 
	        {             
	            that.$toast.success("day grid '"+name+"' imported'", 'Ok', { timeout: 1000, position: "topRight" });
	            that.showProgressBar = false;
				that.$emit("reload");
	            that.$forceUpdate();
	      }).catch((err) => {showError( that.$toast, "grid not imported", err); that.showProgressBar = false;})
	    },	
		startLoader()
	    {
	    	if ( !this.loadingActive)
	      	{
		      	this.loadingActive = true;
		      	this.loader = this.$loading.show({
		                    // Optional parameters
		                    container: this.$refs.formContainer,
		                    canCancel: true,
		                    programmatic: false,
		                    onCancel: this.onCancel,
		                    color: '#000000',
						    loader: 'dots',
						    width: 64,
						    height: 64,
						    active: true,
						    backgroundColor: '#ffffff',
						    opacity: 0.5,
						    zIndex: 999,
		                });
		    }
	    },
		stopLoader()
		{
			clearInterval( timers.pop());
			this.loadingActive = false;
			this.loader.hide();
		},
  },
  mounted() {

  },
  created() {
	  initAppMode();
  },
  computed: {
    
    }
 }
</script>
<style lang="scss" scoped>
.reqLineBG2 {
    cursor: pointer;
    color: #fff;
	background-color: #888 !important;
	//box-shadow: 0pt 2pt 0pt 0 #888;
}
.uploadArea {
 float: left; 
 width: 100%;  
 height: calc(100vH - 120px);
 border: 1pt dotted #ccc;
}
.transition {
 	transition: all .8s;
   -webkit-transition: all .8s;
 }
 .breakValL {
   display: inline-block;
   width: 180pt;
   white-space: nowrap; /* Don't forget this one */
   text-overflow: ellipsis; 
   overflow: hidden;
 }
 .nobold {
 	font-weight: normal !important;
 }
 .breakVal {
   display: inline-block;
   width: 50pt;
   white-space: nowrap; /* Don't forget this one */
   text-overflow: ellipsis; 
   overflow: hidden;
   
 }
 .breakVal2 {
   display: inline-block;
   width: 75pt;
   white-space: nowrap; /* Don't forget this one */
   text-overflow: ellipsis; 
   overflow: hidden;
 }
 .right {
   text-align: right !important;
   padding-right: 3pt; 
 }
 .dontWrap {
   white-space: nowrap; /* Don't forget this one */
   text-overflow: ellipsis; 
 }
 .small
 {
	font-size: 9pt;
 }
 .myButton {
 	//width: 18%;
     padding: 6px 8px;
     outline: none;
     border-radius: 3px;
     height: 22pt;
     font-size: 9pt;
     background-color: #eef;
     border: 1px outset #aaa;
     color: rgb(0, 0, 0);
     margin-top: 4pt;
     margin-right: 4pt;
     margin-bottom: 4pt;
 }
 .tab {
	 padding: 6px 8px;
     outline: none;
     border-radius: 9px 0px 0px 0px;
     height: 22pt;
     font-size: 9pt;
     background-color: #eef;
     border: 1px outset #aaa;
     color: rgb(0, 0, 0);
     margin-top: 4pt;
     margin-right: 0pt;
     margin-bottom: 4pt;
 }
 //.breakLine:hover { background-color: #888 !important; color: #fff; text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.7); }
</style>