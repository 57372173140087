  <template>
  <div v-show="showMe" class='popup' v-click-outside="close" :style='getAppStyle()'> 
    <div style='width: 100% !important;'>
       <div style='display: block; float: left; border-bottom: 1pt solid grey; width: 100% !important;'>
        <div style='display: inline-block; padding-top: 3pt; padding-left: 3pt;float: left;'>
        	Spot log {{spot.id}}
		</div>

		<div :style="getAppStyle()+'float:right; padding-right: 5pt;'" >
        <WindowCloseBN @click="close"/>
		</div>
      </div>
      <div style='display: block; float: left; height: 80%; width: 100% !important; overflow-y: auto;'>
        
         <div v-for="(h,i) in data" :key="'h'+i" >
            <div style='display:block; float: top; width: 100%; border-bottom: 1px solid grey;'>
	         	<div v-for="(k,ii) in h" :key="'hk'+ii" style='display: inline-flex; width: 10%; border-right: 1px solid grey;'>
	         		{{ii}}
	            </div>
         	</div>
         	
         </div>
         
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { getAppStyle, isDarkMode, adapt, getBGColor } from '@/AppStyle.js';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
export default {
  name: "EMPTY",
   components : {
	   WindowCloseBN
  },
  props: {
   
  },
  data(){
    return {
    	showMe: false,
    	data: null,
    	spot: {},
    	getAppStyle
     }
    },
    directives:{
        ClickOutside,
      },
    methods: {
        open( data, spot) {
        	//console.log( JSON.stringify(data))
        	this.showMe = true;
        	let list = [];
        	this.spot = spot;
        	for ( let i in data )
        	{
        		let elem = data[i];
        		if ( Object.keys(elem).length > 0)
        		{
        			list.push( elem)
        		}
        	}
        	this.data = list;
        },
        close() {
        	
        	this.showMe = false;
        },
  },
   updated() {
    
  },
  computed: {
	
  },
  
  created() {
  			
  },
   watch:
   {
   	   
   }
}
</script>
<style  scoped>
.popup {
  display: flex;
  border: 2pt outset #aaa;
  
  width: calc(100vH) !important;
  height: calc(40vH) !important;
  position: absolute !important;
  top: calc(10vH) !important;
  left: calc(10vH) !important;
  z-index: 10;
 
}
</style>